<template>
  <v-data-table :headers="headers" :items="devices" :search="search" :loading="loading">
    <template v-slot:top>
      <v-toolbar flat color="#598639" dark>
        <v-toolbar-title>{{ $t("devices") }}</v-toolbar-title>
        <v-divider class="mx-4" inset vertical></v-divider>
        <v-spacer></v-spacer>
        <v-text-field
        v-model="search"
        append-icon="mdi-magnify"
        label="Search"
        single-line
        hide-details
        clearable
      ></v-text-field>
      <v-spacer></v-spacer>
        <v-dialog v-model="dialog" max-width="600px">
          <template v-slot:activator="{ on, attrs }">
            <v-btn color="black" dark class="mb-2 " v-bind="attrs" v-on="on">
              {{ $t("Add Device") }}
            </v-btn>
          </template>
          <v-card style="border-style: solid;  border-color: white;">
            <v-toolbar color="black">
              <v-toolbar-title >
               <v-icon large class="mr-3 mb-2" color="white">mdi-connection</v-icon> <span class="text-h5 white--text">{{ formTitle }}</span>
              </v-toolbar-title>
            </v-toolbar>
            <v-card-text >
              <v-form v-model="valid" refs="form">
              <v-container class="mt-3" >
                <v-row>
                  <v-col cols="12" >
                    <v-text-field
                      dense
                      v-model="editedItem.name"
                      :label="$t('Name')"
                      :counter="45"
                      :rules="rules"
                      required
                      outlined
                    ></v-text-field>
                  </v-col>
                  <v-col cols="12" >
                    <v-select
                          dense
                          v-model.number="editedItem.type"
                          :label="$t('Type')"
                          :items="devices_types"
                          outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" >
                    <v-select
                          dense
                          v-model.number="editedItem.category"
                          :label="$t('Category')"
                          :items="categories"
                          item-text="name"
                          item-value="id"
                          persistent-hint
                          outlined
                    ></v-select>
                  </v-col>
                  <v-col cols="12" >
                    <v-row>
                      <v-col cols="3" >
                        <v-text-field
                          dense
                          outlined
                          v-model="computedHex"
                          :label="$t('HEX value')"
                          readonly
                        ></v-text-field>
                      </v-col>
                      <v-col>
                        <v-text-field
                          dense
                          v-model.number="editedItem.address"
                          :label="$t('Address')"
                          :counter="4"
                          type="number"
                          required
                          outlined
                        ></v-text-field>
                      </v-col>
                    </v-row>
                  </v-col>
                  <v-col cols="12" >
                    <v-select
                      dense
                      v-model="editedItem.bus_id"
                      :label="$t('Bus ID')"
                      :items="buses"
                      item-text="name"
                      item-value="id"
                      persistent-hint
                      outlined
                      offset-y
                      bottom
                    >
                    <template v-slot:selection="{item }">
                      <span class="text-h6 mb-1">{{item.name}}</span><span class="text-caption ml-2">bus type: {{item.bus_type}} host: {{item.host || "---"}}  port: {{item.port}}</span> 
                    </template>
                    <template v-slot:item="{item }">
                      <span class="text-h6 mb-1">{{item.name}}</span><span class="text-caption ml-2">bus type: {{item.bus_type}} host: {{item.host || "---"}}  port: {{item.port}}</span> 
                    </template>
                    </v-select>
                  </v-col>
                  
                  <v-col cols="12">
                    <v-row>
                      <v-col cols="6">
                        <v-checkbox
                          v-model="editedItem.status"
                          :label="$t('Active')"
                          color="indigo darken-3"
                          :true-value="1"
                          :false-value="0"
                          
                        ></v-checkbox>
                      </v-col>
                    </v-row>

                  </v-col>
                </v-row>
              </v-container>
              </v-form>
            </v-card-text>

            <v-card-actions>
              <v-btn color="warning" @click="close"> {{$t('Cancel')}} </v-btn>
               <v-spacer></v-spacer>
              <v-btn color="vinland" class="white--text" @click="save" :disabled="!valid" :loading="loading2"> {{$t('Save')}}</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
        <v-dialog v-model="dialogDelete" max-width="500px">
          <v-card>
            <v-card-title class="text-h5"
              >{{$t('Are you sure you want to delete this item?')}}</v-card-title
            >
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeDelete"
                >{{$t('Cancel')}}</v-btn
              >
              <v-btn color="blue darken-1" text @click="deleteItemConfirm"
                >{{$t('OK')}}</v-btn
              >
              <v-spacer></v-spacer>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-toolbar>
    </template>
     <template v-slot:item.bus_id="{ item }">
        <span> {{buses.find(v => v.id === item.bus_id).name}}</span><span class="text-caption">(id:{{item.bus_id}})</span>
    </template>
    <template v-slot:item.address="{ item }">
        <span>{{ item.address}} | {{ parseInt(item.address).toString(16) }} hex </span>
    </template>
    
    <template v-slot:item.status="{ item }">
        <v-icon v-if="item.status === 1"   color="green"> mdi-check-bold </v-icon>
        <v-icon v-else  color="red"> mdi-cancel</v-icon>
    </template>
    <template v-slot:item.actions="{ item }">
      <v-icon  color="blue" class="mr-2" @click="editItem(item)"> mdi-pencil </v-icon>
      <v-icon  color="red" @click="deleteItem(item)"> mdi-delete </v-icon>
    </template>
    <template v-slot:no-data>
      <v-btn color="primary" @click="initialize"> Reset </v-btn>
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "devices" ,

  data() {
    return {
        dialog: false,
        dialogDelete: false,
        devices: [],
        devices_types: [],
        buses: [],
        categories: [],
        valid: false,
        loading: false,
        loading2: false,
        rules: [
          v => v !== ''||  this.$t('Item is required'),
          v => v.length <= 45 || this.$t('A maximum  characters exceeded')
        ],
        editedItem: {
          id: 0,
          name: '',
          status: 0,
          type: '',
          address: null ,
          bus_id: null,
        
        },
        defaultItem: {
          id: 0,
          name: '',
          status: 0,
          type: '',
          address: null ,
          bus_id: null,
        },
        editedIndex: -1,
        search: '',
        headers: [
          {
            text: this.$t('ID'),
            align: 'start',
            sortable: true,
            value: 'id',
          },
          {
            text: this.$t('Name'),
            align: 'center',
            sortable: true,
            value: 'name',
          },
          {
            text: this.$t('Type'),
            align: 'center',
            sortable: true,
            value: 'type',
          },
          {
            text: this.$t('Address'),
            align: 'center',
            sortable: true,
            value: 'address',
          },
          {
            text: this.$t('Bus ID'),
            align: 'center',
            sortable: true,
            value: 'bus_id',
          },
          {
            text: this.$t('Active'),
            align: 'center',
            sortable: true,
            value: 'status',
          },
          {
            text: this.$t('Actions'),
            align: 'center',
            value: 'actions',
          },
        
        
        ]
        
    }
  },
  methods: {
      async initialize () {
        try {
          this.loading = true
          const res = await this.$http.get("/device")
          this.devices = res.data
          const bus = await this.$http.get("/bus")
          this.buses = bus.data
          const categories = await this.$http.get("/categories")
          this.categories = categories.data
          const typ = await this.$http.get("/device/types")
          this.devices_types = typ.data.types
        } catch (e) {
          this.$emit("alertMsg", {
            type: "error",
            text: e.message + "\n" + e.response?.data?.error,
          });
          console.log(e)
          this.loading = false
        } finally {
          this.loading = false
        }
      
    },
    
    editItem (item) {
      this.editedIndex = this.devices.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialog = true
    },

    deleteItem (item) {
      this.editedIndex = this.devices.indexOf(item)
      this.editedItem = Object.assign({}, item)
      this.dialogDelete = true
    },

    async deleteItemConfirm () {
      let res = {};
      try {
          res = await this.$http.delete(`/device/${this.editedItem.id}`)
          this.devices.splice(this.editedIndex, 1)
          this.closeDelete()
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.closeDelete()
        } 
    },

    close () {
      this.dialog = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },

    closeDelete () {
      this.dialogDelete = false
      this.$nextTick(() => {
        this.editedItem = Object.assign({}, this.defaultItem)
        this.editedIndex = -1
      })
    },
    validate () {
        return this.$refs.form.validate()
    },

    async save () {
      let res = null
      if (this.editedIndex > -1) {
        try {
          this.loading2 = true
          res = await this.$http.put("/device", this.editedItem)
          Object.assign(this.devices[this.editedIndex], this.editedItem)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading2 = false
        } finally{
          this.loading2 = false
        }
        
      } else {
        try {
          this.loading2 = true
          res = await this.$http.post("/device", this.editedItem)
          this.devices.push(res.data)
          this.$emit("alertMsg", {
            type: "success",
            text: "Data saved OK",
          });
        } catch (e) {
          this.$emit('alertMsg', { type: 'error', text: e.message +"\n" + res?.error})
          this.loading2 = false
        } finally{
          this.loading2 = false
        }
      }
      this.close()
    },
  },

  computed: {
      formTitle () {
        return this.editedIndex === -1 ? this.$t('Add new Device') : this.$t('Edit Device')
      },
      computedHex() {
        return   parseInt(this.editedItem.address).toString(16)  ||  0
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },
      dialogDelete (val) {
        val || this.closeDelete()
      },
      editedItem: {
        handler() {
          this.$refs.form.validate()
        },
        deep: true
      }
    },

    created () {
      this.initialize()
    },
  
    

}
</script>

<style scoped>
.vinland {
  background-color: #196619 !important;
}
</style>